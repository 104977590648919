import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategoriesThunk} from '../../../store/categories';
import { addItemThunk } from '../../../store/items';
import swal from "sweetalert";

function AddProducts() {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories); // Retrieve categories from the Redux store
  
    const [formData, setFormData] = useState({
      name: '',
      description: '',
      price: '',
      category_id: '',
      image: null,
      chinese_name: '',
    });
  
    const [imagePreview, setImagePreview] = useState(null);
  
    useEffect(() => {
      // Dispatch action to get categories when the modal opens
      dispatch(getCategoriesThunk());
    }, [dispatch]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
  
      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    };

    const removeImage = () => { 
        setFormData((prevData) => ({
            ...prevData,
            image: null,
        }));
        setImagePreview(null);
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try{
      const newItem = await dispatch(addItemThunk(formData));
      if(newItem){ 
        swal("Success!", "New Product Added", "success");
        setFormData({
            name: '',
            description: '',
            price: '',
            category_id: '',
            image: null,
            chinese_name: '',
            });
        setImagePreview(null);
      }else { 
        swal("Error!", "Product not added Try Again", "error");
      }
      } catch (error) {
        console.log(error);
      }
    };
  

  return (
    <div>
      <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Products</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Demo Product Name"
                        value={formData.name}
                        name="name"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Chinese Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="中文名"
                        value={formData.chinese_name}
                        name="chinese_name"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Price</label>
                      <input
                        type="decimal"
                        className="form-control"
                        placeholder="$Price (10.99)"
                        value={formData.price}
                        name="price"
                        onChange={handleInputChange}
                        min={0}
                        step={1}
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Category</label>
                      <select
                        className="form-control"
                        value={formData.category_id}
                        name="category_id"
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Category</option>
                        {categories?.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      </div>
                  </div>
                    <div className="form-group mb-3">
                        <label>Description</label>
                        <textarea
                        className="form-control"
                        placeholder="Description"
                        value={formData.description}
                        name="description"
                        onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="form-group mb-3">
                        <label>Image</label>
                        <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={handleImageChange}
                        />
                        {imagePreview && (
                            <div>
                        <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ height: 100, marginTop: 10 }}
                        />
                        <button type="button" className="btn btn-danger" onClick={removeImage}><i className="fa fa-trash"></i></button>
                        </div>
                        )}
                    </div>
                  <button type="submit" className="btn btn-primary">
                    Add Product
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default AddProducts;
