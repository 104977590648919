import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/hutao2.png";
import logo1 from "../../../images/hutaologo1.png";
import name from "../../../images/hutaoname-removebg.png";

export function  NavMenuToggle(){
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}
	},200);
}

const NavHader = () => {
   const [toggle, setToggle] = useState(false);

   return (
      <div className="nav-header">
         <Link to={"/"} className="brand-logo mt-2">
         <img className="logo-abbr" src={logo1} alt="" />
            <img className="logo-compact" src={name} alt="" />
            <img className="brand-title" src={name} alt="" />
         </Link>

         <div className="nav-control" onClick={() => 
               {
                  setToggle(!toggle)
                  NavMenuToggle();
               }}
            >
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
