import { getToken } from "./utlits";

const GET_COUPONS = "coupons/GET_COUPONS";
const CREATE_COUPON = "coupons/CREATE_COUPON";
const DELETE_COUPON = "coupons/DELETE_COUPON";


const getAllCoupons = (coupons) => ({
    type: GET_COUPONS,
    coupons,
    });

const createCoupon = (coupon) => ({
    type: CREATE_COUPON,
    coupon,
});

const deleteCoupon = (couponId) => ({
    type: DELETE_COUPON,
    couponId,
});


export const getAllCouponsThunk = () => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/coupons/admin`, { 
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        const coupons = await response.json();
        dispatch(getAllCoupons(coupons));
    }
}

export const createCouponThunk = (coupon) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/coupons/admin`, { 
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(coupon)
    });

    if (response.ok) {
        const newCoupon = await response.json();
        dispatch(createCoupon(newCoupon));
    }
}

export const deleteCouponThunk = (couponId) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/coupons/admin/${couponId}`, { 
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        dispatch(deleteCoupon(couponId));
    }
}

const initialState = { coupons: []};

export default function couponsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_COUPONS:
            return { 
                ...state,
                coupons: action.coupons
            }
        case CREATE_COUPON:
            return { 
                ...state,
                coupons: [...state.coupons, action.coupon]
            }
        case DELETE_COUPON:
            return { 
                ...state,
                coupons: state.coupons.filter(coupon => coupon.coupon_id !== action.couponId)
            }
        default:
            return state;
    }
}
