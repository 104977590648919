import { getToken } from "./utlits";

const GET_CUSTOMERS = 'customers/GET_CUSTOMERS';

const getCustomers = (customers) => ({
    type: GET_CUSTOMERS,
    customers
});


export const getCustomerThunk = (restaurant_id, page) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/restaurants/customers/${restaurant_id}?page=${page}`, { 
        headers: {
            'authorization': `Bearer ${token}`
        }
    
    });

    if (response.ok) {
        const customers = await response.json();
        dispatch(getCustomers(customers));
    }
}

const initialState = { customers: [] };

export default function customersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMERS:
           return { 
                ...state,
                customers: action.customers
           }
        default:
            return state;
    }
}
