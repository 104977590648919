import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Pagination, Modal, Row, Col, Table } from "react-bootstrap";
import { getAllOrdersThunk } from "../../../store/orders";
import OrderDetailsModal from "./OrderDetailModal";
import { getFeesThunk } from "../../../store/fees";

function AllOrders() {
    const dispatch = useDispatch();
    const orders = useSelector((state) => state.orders.allOrders);
    const id = useSelector((state) => state.session.user.id);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const fees = useSelector((state) => state.fees.fees);
  
    useEffect(() => {
      dispatch(getAllOrdersThunk(id, currentPage));
    }, [dispatch, id, currentPage]);

    useEffect(() => { 
      dispatch(getFeesThunk())
    },[dispatch])
  
    const handleOrderClick = (order) => {
      setSelectedOrder(order);
      setShowModal(true);
    };
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    };
  
    // Function to format the date as '2/14/24'
    const formatDate = (date) => {
      const formattedDate = new Date(date);
      return `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()}`;
    };
  
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">ALL ORDERS</h5>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>#Order</th>
                  <th>Order Time</th>
                  <th>Customer Info</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {orders &&
            orders.filter((order) => order.order_status !== "HOLD").map((order, index) => (
              
                  <React.Fragment key={order.id}>
                    {index > 0 && formatDate(order.createdAt) !== formatDate(orders[index - 1].createdAt) && (
                      <tr className="text-center">
                        <td colSpan="5">
                          <strong>{formatDate(order.createdAt)}</strong>
                        </td>
                      </tr>
                    )}
                    <tr key={order.id} style={{ cursor: "pointer", fontSize: "16px", lineHeight: "1.1rem" }} onClick={() => handleOrderClick(order)}>
                      <td>#{order.order_id}</td>
                      <td>{new Date(order.createdAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })}</td>
                      <td>
                      <p>Name: {order.name}<br/>Phone: {order.phone_number}</p>
                      </td>
                      <td>${order?.order_total}</td>
                      <td>
                      {order.payment_method === "in-store" ? (
                    <>
                    <span className="badge bg-danger">PAY IN STORE</span>
                    <br/>
                    {order?.address && order?.address !== 'Pickup' ? ( 
                      <span className="badge bg-success">REQUIRE DELIVERY</span>
                    ) : ( 
                      <span className="badge bg-success">PICKUP</span>
                    )}
                    </>
                  ) : (
                    <>
                    <span className="badge bg-success">PAID ONLINE</span>
                    <br/>
                    {order?.address && order?.address !== 'Pickup' ? ( 
                      <span className="badge bg-success">REQUIRE DELIVERY</span>
                    ) : ( 
                      <span className="badge bg-success">PICKUP</span>
                    )}
                    </>
                  )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
              </Pagination>
            </div>
          </div>
        </div>
        <OrderDetailsModal showModal={showModal} selectedOrder={selectedOrder} setShowModal={setShowModal} fees={fees} />
      </div>
    );
  }
  
  export default AllOrders;
  
