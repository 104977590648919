import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Table } from "react-bootstrap";
import { getCustomerThunk } from "../../../store/customers";

function AllCustomers() {
    const dispatch = useDispatch();
    const id = useSelector((state) => state.session.user.restaurant_id);
    const customers = useSelector((state) => state.customers.customers);
    const [currentPage, setCurrentPage] = useState(1);
  
    useEffect(() => {
      dispatch(getCustomerThunk(id, currentPage));
    }, [dispatch, id, currentPage]);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short"
        };
        return date.toLocaleDateString("en-US", options);
    }
      
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">All Customers</h5>
            <Table responsive striped bpersoned hover>
              <thead>
                <tr>
                  <th>Id#</th>
                  <th>Customer Name</th>
                  <th>Customer Phone</th>
                  <th>Since</th>
                </tr>
              </thead>
              <tbody>
                {customers?.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer?.id}</td>
                    <td>{customer?.name} </td>
                    <td>{customer?.phone}</td>
                    <td>{formatTimestamp(customer?.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default AllCustomers;
  
