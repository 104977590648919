import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import * as categoryActions from '../../../store/categories';
import swal from 'sweetalert';

function AllCategories() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const [isModalOpen, setModalOpen] = useState(false);
  const [editedCategory, setEditedCategory] = useState({
    name: '',
    index: 0,
  });
  const [isNewCategory, setNewCategory] = useState(false);

  useEffect(() => {
    dispatch(categoryActions.getCategoriesThunk());
  }, [dispatch]);

  const openModal = (category) => {
    setNewCategory(!category);
    setEditedCategory(category || {});
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setEditedCategory({});
  };

  const handleNameChange = (e) => {
    setEditedCategory({ ...editedCategory, name: e.target.value });
  };

  const handleIndexChange = (e) => {
    setEditedCategory({ ...editedCategory, index: e.target.value });
  };

  const saveChanges = () => {
    if (isNewCategory) {
      dispatch(categoryActions.addCategoryThunk(editedCategory));
    } else {
      dispatch(categoryActions.editCategoryThunk(editedCategory));
    }
    closeModal();
  };

  const deleteCategory = (categoryId) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this category!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch(categoryActions.deleteCategoryThunk(categoryId));
      }
    });
  };

  return (
    <div>
      <Button variant="primary" className='mb-3' onClick={() => openModal(null)}>Add Category</Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories?.map((category) => (
            <tr key={category.id}>
              <td>{category.index}</td>
              <td>{category.name}</td>
              <td>
                <Button variant="info" onClick={() => openModal(category)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => deleteCategory(category.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isNewCategory ? 'Add Category' : 'Edit Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={editedCategory.name || ''}
                onChange={handleNameChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Index</Form.Label>
              <Form.Control
                type="number"
                name="index"
                value={editedCategory.index || ''}
                onChange={handleIndexChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveChanges}>Save Changes</Button>{' '}
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllCategories;
