import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemsThunk, deleteItemThunk } from "../../../store/items"; // Import the deleteItemThunk action
import swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import { getAllAttributesThunk } from "../../../store/attributes";
import { createItemAttributeThunk, deleteItemAttributeThunk } from "../../../store/items";
import Select from "react-select";
import EditProducts from "./EditProducts";
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

function AllProduct() {
  const dispatch = useDispatch();
  const items = useSelector(state => state.items.items);
  const attributes = useSelector(state => state.attributes.attributes);
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null); // State to track selected item ID

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEditOption, setSelectedEditOption] = useState(null);
  const [selectEditItem, setSelectEditItem] = useState(null);
  const [collapsedCategories, setCollapsedCategories] = useState({}); // State to track collapsed categories
  const [expandState, setExpandState] = useState(false);

  useEffect(() => { 
    dispatch(getItemsThunk());
    dispatch(getAllAttributesThunk());
  }, [dispatch]);

  useEffect(() => {
    const initialCollapsedState = items.reduce((acc, item) => {
      const category = item.Category;
      acc[category.id] = true; // All categories are initially collapsed
      return acc;
    }, {});
    setCollapsedCategories(initialCollapsedState);
  }, [items]);

  const handleEditModal = (option, item) => {
    setSelectEditItem(item);
    setSelectedEditOption(option);
    setShowEditModal(true);
  };

  const handleDeleteItem = async (item) => {
    try {
      const deleted = await dispatch(deleteItemThunk(item));
      if (deleted && deleted.status === 200) {
        swal("Item deleted successfully", {
          icon: "success",
        });
      } else {
        swal("Failed to delete item", {
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      swal("Failed to delete item", {
        icon: "error",
      });
    }
  };

  const handleAttributeChange = (selectedOptions) => {
    setSelectedAttributes(selectedOptions);
    
    // Dispatch add or remove actions for each selected attribute
    const currentAttributeIds = selectedAttributes?.map((attribute) => attribute.value);
    const newAttributeIds = selectedOptions?.map((option) => option.value);

    // Add attributes that are not in the current selection
    newAttributeIds.filter((id) => !currentAttributeIds?.includes(id)).forEach((id) => {
      if (selectedItemId) {
        dispatch(createItemAttributeThunk(selectedItemId, id));
      }
    });

    // Remove attributes that are not in the new selection
    currentAttributeIds?.filter((id) => !newAttributeIds?.includes(id)).forEach((id) => {
      if (selectedItemId) {
        dispatch(deleteItemAttributeThunk(selectedItemId, id));
      }
    });
  };

  const handleToggleCategory = (categoryId) => {
    setCollapsedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const groupedItems = items.reduce((acc, item) => {
    const category = item.Category;
    if (!acc[category.id]) {
      acc[category.id] = {
        name: category.name,
        items: [],
      };
    }
    acc[category.id].items.push(item);
    return acc;
  }, {});

  const handleExpandAndCollapseAll = (expand) => {
    const newCollapsedState = Object.keys(collapsedCategories).reduce((acc, categoryId) => {
      acc[categoryId] = expand;
      return acc;
    }, {});
    setCollapsedCategories(newCollapsedState);
    setExpandState(expand);
  };



  return (
    <Fragment>
      <p className="text-warning">Product Already Exists In Order Cant Not Be Deleted Only Can Be Disabled!</p>

      <div className="d-flex mb-2">
        <Button variant="primary" onClick={() => handleExpandAndCollapseAll(!expandState)}>{!expandState ? 'CLOSE ALL' : 'OPEN ALL'}</Button>
      </div>


      <div>
        {Object.keys(groupedItems).map((categoryId) => (
          <div key={categoryId} className="mb-3">
            <h3 onClick={() => handleToggleCategory(categoryId)} style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }}>
              {collapsedCategories[categoryId] ? <FaChevronRight /> : <FaChevronDown />}
              <span className="ml-2">{groupedItems[categoryId].name} ({groupedItems[categoryId].items.length})</span>
            </h3>
            {!collapsedCategories[categoryId] && (
              <div className="row ml-3">
                {groupedItems[categoryId].items.map((item) => (
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4" key={item.id}>
                    <div className="card cursor-pointer" 
                      onClick={() => { 
                        swal({ 
                          title: "Item Details",
                          text: `Name: ${item.name} \n Price: ${item.price} \n Description: ${item.description}`,
                          icon: "info",
                          buttons: {
                            edit: { 
                              text: "Edit",
                              value: "edit",
                              className: "btn-info",
                            },
                            add: { 
                              text: "Add Attribute",
                              value: "add",
                              className: "btn-success",
                            },
                            delete: {
                              text: "Delete",
                              value: "delete",
                              className: "btn-danger",
                            },
                          },
                          dangerMode: true,
                        }).then((value) => {
                            if(value === 'delete'){ 
                            swal("Are you sure you want to delete this item?", {
                              buttons: ["No", "Yes"],
                              }).then((value) => {
                              if(value){ 
                                handleDeleteItem(item);
                              }
                            });
                          } else if(value === 'add'){ 
                            setShowAttributeModal(true);
                            setSelectedItemId(item.id); // Set selected item ID
                            setSelectedAttributes(item?.Restaurant_Item_Attributes?.map((attr) => ({ value: attr.Item_Attribute.id, label: attr?.Item_Attribute?.name }))); // Autofill selected attributes
                          } else if(value === 'edit'){ 
                            handleEditModal("",item);
                          }
                        });
                      }}
                    >
                      <div className="card-body">
                        <div className="new-arrival-product">
                          <div className="new-arrivals-img-contnent">
                            {item?.image_url && (
                              <img className="img-fluid" style={{maxHeight: '150px', height: '150px', objectFit: 'cover'}} src={item?.image_url} alt="" />
                            )}
                          </div>
                          <div className="new-arrival-content text-center mt-3">
                            <h4>
                              <span className="text-black">{item?.chinese_name}</span>
                              <br />
                              <span className="text-black">{item?.name}</span>
                            </h4>
                            <span className="price">${item?.price}</span>
                            <br />
                            {item?.hidden && <span className="text-danger">Disabled</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal show={showAttributeModal} centered size="lg" onHide={() => setShowAttributeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Attribute</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <Select
              options={attributes?.map((attribute) => ({ value: attribute.id, label: attribute.name }))}
              isMulti
              onChange={handleAttributeChange}
              value={selectedAttributes}
            />
          </div>
        </Modal.Body>
      </Modal>

      <EditProducts showEditModal={showEditModal} setShowEditModal={setShowEditModal} selectedEditOption={() => setShowEditModal(false)} selectEditItem={selectEditItem}/>
    </Fragment>
  );
}

export default AllProduct;
