import React, { useEffect, useState } from "react";
import { Button, Table, Container, Row, Col, Alert, Modal, Badge} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersThunk, updatePrepTimeThunk, updateStatusThunk } from "../../../store/orders";
import OrderDetailsModal from "./OrderDetailModal";
import { setNotification } from "../../../store/notifications";
import { getFeesThunk } from "../../../store/fees";

function InComingOrders() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const id = useSelector((state) => state.session.user.id);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNewOrderAlert, setShowNewOrderAlert] = useState(false);
  const [preparationTime, setPreparationTime] = useState(5);
  const [showPreparationTimeModal, setShowPreparationTimeModal] = useState(false);
  const fees = useSelector((state) => state.fees.fees);



  useEffect(() => {
    dispatch(getOrdersThunk(id));
    dispatch(getFeesThunk())
  }, [dispatch, id]);


  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
    setShowNewOrderAlert(false)
  };

  const handleStartOrder = (order) => {
    setSelectedOrder(order);
    setPreparationTime(5);
    setShowPreparationTimeModal(true);
    setShowNewOrderAlert(false);
  };

  const handleConfirmStartOrder = () => {
    dispatch(updatePrepTimeThunk(selectedOrder.id, preparationTime));
    setShowPreparationTimeModal(false);
  };

  const handleCloseOrder = (order) => {
    dispatch(updateStatusThunk(order.id));
  };

  return (
    <Container className="border bg-white" style={{borderRadius:'15px', padding:'15px'}}>
      <Row className="mt-3 mb-3">
      <Col className="d-flex align-items-center justify-content-between">
  <h2 className="mb-0" style={{ fontSize: '24px', fontWeight: 'bold' }}>{formatDate(new Date())}</h2>
  <div className="d-flex gap-2">
    <Badge bg="danger" className="badge-circle">
      New
    </Badge>
    <Badge bg="warning" className="badge-circle">
      Cooking
    </Badge>
    <Badge bg="success" className="badge-circle">
      Done
    </Badge>
  </div>
</Col>


        <Col>
          {showNewOrderAlert && (
            <Alert variant="danger" onClick={() => setShowNewOrderAlert(false)} dismissible>
              <strong>New Order!</strong> Unaccepted Orders Are All In Red Below!
            </Alert>
          )}
        </Col>
      </Row>
      <Table  bordered hover responsive>
        <thead>
          <tr>
            <th>#Order</th>
            <th>Order Time</th>
            <th>Customer Info</th>
            <th>Total</th>
            <th>Status</th>
            <th>Action</th>
            <th>Ready</th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.filter((order) => order.order_status !== "HOLD").map((order) => (
              
              <tr
                key={order.id}
                style={{ cursor: "pointer", fontSize: "16px", lineHeight: "1.1rem" }} 
                className={order?.order_status === "CLOSE" ? "table-success" : order.preparation_time ? "table-warning" : "table-danger"}
              >
                <td style={{ cursor: "pointer" }} onClick={() => handleOrderClick(order)}>
                  #{order.order_id}
                </td>
                <td style={{ cursor: "pointer" }} onClick={() => handleOrderClick(order)}>
                  {new Date(order.createdAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })}
                </td>
                <td style={{ cursor: "pointer" }} onClick={() => handleOrderClick(order)}>
                <p>{order.name}<br/>{order.phone_number}</p>
                </td>
                <td style={{ cursor: "pointer" }} onClick={() => handleOrderClick(order)}>
                  ${order?.order_total}
                </td>
                <td style={{ cursor: "pointer" }} onClick={() => handleOrderClick(order)}>
                  {order.payment_method === "in-store" ? (
                    <>
                    <span className="badge bg-danger">PAY IN STORE</span>
                    <br/>
                    {order?.address && order?.address !== 'Pickup' ? ( 
                      <span className="badge bg-success">REQUIRE DELIVERY</span>
                    ) : ( 
                      <span className="badge bg-success">PICKUP</span>
                    )}
                    </>
                  ) : (
                    <>
                    <span className="badge bg-success">PAID ONLINE</span>
                    <br/>
                    {order?.address && order?.address !== 'Pickup' ? ( 
                      <span className="badge bg-success">REQUIRE DELIVERY</span>
                    ) : ( 
                      <span className="badge bg-success">PICKUP</span>
                    )}
                    </>
                  )}
                </td>
                <td>
                  {order?.preparation_time == null ? (
                    <Button variant="primary" onClick={() => handleStartOrder(order)}>
                      Start
                    </Button>
                  ) : (
                    <Button variant="primary" disabled>
                      {order?.preparation_time} min
                    </Button>
                  )}
                </td>
                <td>
                  {order?.order_status === "CLOSE" ? (
                    <Button variant="primary" disabled>
                      CLOSED
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={() => handleCloseOrder(order)}>
                      Ready
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {showPreparationTimeModal && selectedOrder && (
  <Modal show={showPreparationTimeModal} centered>
    <Modal.Body className="text-center">
      <h6>Select Preparation Time (minutes):</h6>
      <div className="d-flex justify-content-center flex-wrap">
        {[...Array(24).keys()].map((min) => (
          <Button
            key={min}
            variant={preparationTime === (min + 1) * 5 ? "primary" : "outline-primary"}
            className="m-2"
            onClick={() => setPreparationTime((min + 1) * 5)}
          >
            {(min + 1) * 5} min
          </Button>
        ))}
      </div>
      <Button variant="primary" className="mt-3" onClick={handleConfirmStartOrder}>
        Confirm Start Order
      </Button>
    </Modal.Body>
  </Modal>
)}

            <OrderDetailsModal  showModal={showModal} selectedOrder={selectedOrder} setShowModal={setShowModal} fees={fees} />

    </Container>
  );
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}/${month}/${day} ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
}

export default InComingOrders;
