import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCategoryThunk, addItemPrintersThunk, deleteCategoryThunk, deleteItemPrintersThunk, getPrintersThunk } from "../../../store/printers";
import { Modal, Button, Collapse, Form, Spinner } from "react-bootstrap";
import { getItemsThunk } from "../../../store/items";

function DisplayPrinter() {
    const dispatch = useDispatch();
    const printers = useSelector(state => state.printers.printers);
    const user = useSelector(state => state.session.user);
    const items = useSelector(state => state.items.items);

    const [editPrinterModal, setEditPrinterModal] = useState(false);
    const [openCategory, setOpenCategory] = useState({});
    const [currentPrinter, setCurrentPrinter] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getPrintersThunk(user?.restaurant_id)).then(() => setLoading(false));
        if (!items.length) {
            setLoading(true);
            dispatch(getItemsThunk()).then(() => setLoading(false));
        }
    }, [dispatch, user?.restaurant_id, items.length]);

    useEffect(() => {
        if (currentPrinter) {
            const updatedPrinter = printers.find(printer => printer.id === currentPrinter.id);
            if (updatedPrinter) {
                setCurrentPrinter(updatedPrinter);
            }
        }
    }, [printers]);

    // Group items by category
    const itemsByCategory = items.reduce((acc, item) => {
        const categoryName = item.Category.name;
        if (!acc[categoryName]) {
            acc[categoryName] = [];
        }
        acc[categoryName].push(item);
        return acc;
    }, {});

    const toggleCategory = (category) => {
        setOpenCategory(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const handleSelectAllItemForCategory = async (id) => {
        setLoading(true);
        try {
            await dispatch(addCategoryThunk(currentPrinter.id, id))
                .then(() => {
                    dispatch(getPrintersThunk(user?.restaurant_id));
                }).finally(() => setLoading(false));
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const handleUnselectAllItemForCategory = async (id) => {
        setLoading(true);
        try {
            await dispatch(deleteCategoryThunk(currentPrinter.id, id))
                .then(() => {
                    dispatch(getPrintersThunk(user?.restaurant_id));
                }).finally(() => setLoading(false));
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const handleSelectAnItem = async (id) => {
        setLoading(true);
        try {
            await dispatch(addItemPrintersThunk(currentPrinter.id, id))
                .then(() => {
                    dispatch(getPrintersThunk(user?.restaurant_id));
                }).finally(() => setLoading(false));
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const handleUnselectAnItem = async (id) => {
        setLoading(true);
        try {
            await dispatch(deleteItemPrintersThunk(currentPrinter.id, id))
                .then(() => {
                    dispatch(getPrintersThunk(user?.restaurant_id));
                }).finally(() => setLoading(false));
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const handleCheckAction = (itemId) => {
        if (isItemChecked(itemId)) {
            handleUnselectAnItem(itemId);
        } else {
            handleSelectAnItem(itemId);
        }
    };

    const isItemChecked = (itemId) => {
        return currentPrinter && currentPrinter.Items.some(item => item.id === itemId);
    };

    const isAllSelected = (category) => {
        const itemsInCategory = itemsByCategory[category];
        return itemsInCategory.every(item => isItemChecked(item.id));
    };

    const isNoneSelected = (category) => {
        const itemsInCategory = itemsByCategory[category];
        return itemsInCategory.every(item => !isItemChecked(item.id));
    };

    const getSelectedCount = (category) => {
        const itemsInCategory = itemsByCategory[category];
        return itemsInCategory.filter(item => isItemChecked(item.id)).length;
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">Printers</h1>
            <ul className="list-group">
                {printers.map(printer => (
                    <li key={printer.id} className="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="h5 mb-1">{printer.name}</h3>
                            <p className="mb-0">{printer.ip}</p>
                        </div>
                        <Button variant="primary" onClick={() => {
                            setCurrentPrinter(printer);
                            setEditPrinterModal(true);
                        }}>Edit</Button>
                    </li>
                ))}
            </ul>
            <Modal show={editPrinterModal} onHide={() => setEditPrinterModal(false)} centered size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Printer {currentPrinter?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Iterate over categories */}
                    {Object.keys(itemsByCategory).map(category => (
                        <div key={category} className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 
                                    onClick={() => toggleCategory(category)} 
                                    aria-controls={`collapse-${category}`} 
                                    aria-expanded={openCategory[category]} 
                                    style={{ cursor: "pointer" }}
                                >
                                    {category} ({getSelectedCount(category)} / {itemsByCategory[category].length})
                                </h5>
                                <div style={{ display: 'flex', gap: '20px' }}>
                                    <Button 
                                        variant="success" 
                                        onClick={() => handleSelectAllItemForCategory(itemsByCategory[category][0].Category.id)}
                                        disabled={isAllSelected(category)}
                                    >
                                        Select All
                                    </Button>
                                    <Button 
                                        variant="danger" 
                                        onClick={() => handleUnselectAllItemForCategory(itemsByCategory[category][0].Category.id)}
                                        disabled={isNoneSelected(category)}
                                    >
                                        Unselect All
                                    </Button>
                                </div>
                            </div>
                            <Collapse in={openCategory[category]}>
                                <div id={`collapse-${category}`}>
                                    {itemsByCategory[category].map(item => (
                                        <Form.Check 
                                            key={item.id} 
                                            type="checkbox" 
                                            label={item.name} 
                                            className="mb-2"
                                            checked={isItemChecked(item.id)}
                                            onChange={() => handleCheckAction(item.id)}
                                        />
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditPrinterModal(false)}>
                    {loading ? (
                <div className="loading-overlay">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : ( 
                        "Close" 
                    
            )}
                        
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DisplayPrinter;
