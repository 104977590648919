import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import {  useDispatch, useSelector } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { restoreUser } from './store/session';


const Login = lazy(() => import('./jsx/pages/Login'));

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }
  return ComponentWithRouterProp;
}

function App (props) {
    const dispatch = useDispatch();
	  const navigate = useNavigate();
    const currentUser = useSelector(state => state.session.user);

    
    useEffect(() => {
      dispatch(restoreUser())
    }, [dispatch]);   

    useEffect(() => { 
      //navigate user to login page if not logged in and not on login page
      if (!currentUser && window.location.pathname !== '/login') {
        navigate('/login');
      }
      //navigate user to home page if logged in and on login page
      if (currentUser && window.location.pathname === '/login') {
        navigate('/');
      }
    }
    , [currentUser, navigate]);


   

    let routeblog = (         
      <Routes>
        <Route path='/login' element={<Login />} />
      </Routes> 
    );
    if (currentUser) {
		return (
			<>
          <Suspense fallback={
              <div id="preloader">
                  <div className="sk-three-bounce">
                      <div className="sk-child sk-bounce1"></div>
                      <div className="sk-child sk-bounce2"></div>
                      <div className="sk-child sk-bounce3"></div>
                  </div>
              </div>  
            }
          >
            <Index /> 
          </Suspense>
      </>
  );
	
	}else{
		return (
			<div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
              }
            >
              {routeblog}
            </Suspense>
			</div>
		);
	}
};



export default (App)
