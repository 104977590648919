import { getToken } from "./utlits";

const GET_ITEMS = 'items/GET_ITEMS';
const ADD_ITEM = 'items/ADD_ITEM';
const DELETE_ITEM = 'items/DELETE_ITEM';
const EDIT_ITEM = 'items/EDIT_ITEM';
const CREATE_ITEM_ATTRIBUTE = 'items/CREATE_ITEM_ATTRIBUTE';
const DELETE_ITEM_ATTRIBUTE = 'items/DELETE_ITEM_ATTRIBUTE';
const UPDATE_DISABLE_ITEM = 'items/UPDATE_DISABLE_ITEM';

const apiUrl = 'http://localhost:8000';

const getItems = (items) => ({
    type: GET_ITEMS,
    items
});

const addItem = (item) => ({
    type: ADD_ITEM,
    item
});

const deleteItem = (item) => ({
    type: DELETE_ITEM,
    item
});

const editItem = (item) => ({
    type: EDIT_ITEM,
    item
});

const createItemAttribute = (itemAttribute) => ({
    type: CREATE_ITEM_ATTRIBUTE,
    itemAttribute,
    });

const deleteItemAttribute = (itemAttributeId) => ({
    type: DELETE_ITEM_ATTRIBUTE,
    itemAttributeId,
    });

export const updateDisableItem = (item) => ({
    type: UPDATE_DISABLE_ITEM,
    item
});
    

export const getItemsThunk = () => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/items/` ,
    {
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }

    });

    if(response.ok){
        const data = await response.json();
        dispatch(getItems(data));
        return response;
    }
}

export const addItemThunk = (item) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const { image, name, description, price, category_id, chinese_name } = item;
    
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('description', description);
    formdata.append('price', price);
    formdata.append('category_id', category_id);
    formdata.append('chinese_name', chinese_name);
    if(image){
        formdata.append('image', image);
    }
    console.log(formdata)
    const response = await fetch(`/api/items/`, {
        method: 'POST',
        body: formdata,
        headers: { 
            'authorization': `Bearer ${token}`
        }
    });

    if(response.ok){
        const data = await response.json();
        dispatch(addItem(data));
        return response;
    }
}

export const deleteItemThunk = (item) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/items/${item.id}`, {
        method: 'DELETE',
        headers: { 
            'authorization': `Bearer ${token}`
        }

    });

    if(response.ok){
        dispatch(deleteItem(item));
        return response;
    }
}

export const editItemThunk = (item_id, item) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;
    
    const { image, name, description, price, category_id, chinese_name, imageAction} = item;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('category_id', category_id);
    formData.append('chinese_name', chinese_name);
    formData.append('imageAction', imageAction);
    if(image){
        formData.append('image', image);
    }
    const response = await fetch(`/api/items/${item_id}`, {
        method: 'PUT',
        body: formData,
        headers: { 
            'authorization': `Bearer ${token}`
        }

    });

    if(response.ok){
        const data = await response.json();
        dispatch(editItem(data));
        dispatch(getItemsThunk());
        return response;
    }
}

export const createItemAttributeThunk = (item_id, attribute_id) => async (dispatch) => { 
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/items/${item_id}/attributes/${attribute_id}`, { 
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }

    })

    if(response.ok){
        const data = await response.json();
        dispatch(createItemAttribute(data));
        dispatch(getItemsThunk());
        return response;
    }
}

export const deleteItemAttributeThunk = (item_id, attribute_id) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/items/${item_id}/attributes/${attribute_id}`, {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }

    });

    if(response.ok){
        const data = await response.json();
        dispatch(deleteItemAttribute(data.id));
        dispatch(getItemsThunk());
        return response;
    }
}

export const updateDisableItemThunk = (item_id) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/items/${item_id}/hide`, {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }

    });

    if(response.ok){
        const data = await response.json();
        dispatch(getItemsThunk());
        return response;
    }
}

const initialState = {items: [], item: {}};

const itemsReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_ITEMS:
            return { ...state, items: action.items };
        case ADD_ITEM:
            return { ...state, items: [...state.items, action.item] };
        case DELETE_ITEM:
            return { ...state, items: state.items.filter(item => item.id !== action.item.id) };
      
            case CREATE_ITEM_ATTRIBUTE:
                return {
                    ...state,
                    items: state.items.map(item => { 
                        item.Restaurant_Item_Attributes.push(action.itemAttribute)
                    })
                };
            
            case DELETE_ITEM_ATTRIBUTE:
                return {
                    ...state,
                    items: state.items.map(item => {
                        if (item.id === action.itemAttributeId) {
                            return {
                                ...item,
                                Restaurant_Item_Attributes: item.Restaurant_Item_Attributes.filter(
                                    attribute => attribute.id !== action.itemAttributeId
                                ),
                            };
                        }
                        return item;
                    }),
                };
                
            case UPDATE_DISABLE_ITEM:
                return {
                    ...state,
                    items: state.items.map(item => {
                        if (item.id === action.item.id) {
                            return {
                                ...item,
                                disabled: !item.disabled
                            };
                        }
                        return item;
                    }),
                };
                
        default:
            return state;
    }
}

export default itemsReducer;
