import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { editItemThunk, updateDisableItemThunk } from "../../../store/items";
import { getCategoriesThunk } from "../../../store/categories";

function EditProducts({showEditModal, selectedEditOption, selectEditItem, setShowEditModal}) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        price: "",
        category_id: "",
        image: null,
        chinese_name: "",
        imageAction: "noChange"
    });
    const [itemDisabled, setItemDisabled] = useState(false);

    const categories = useSelector((state) => state.categories.categories);

    useEffect(() => { 
        if(showEditModal){ 
            setFormData({
                name: selectEditItem?.name,
                description: selectEditItem?.description,
                price: selectEditItem?.price,
                category_id: selectEditItem?.Category.id,
                chinese_name: selectEditItem?.chinese_name,
                image: null,
            });

            setItemDisabled(selectEditItem?.hidden);

            if(selectEditItem?.image_url){
                setImagePreview(selectEditItem?.image_url);
            }
        }else { 
            setFormData({
                name: "",
                description: "",
                price: "",
                category_id: "",
                image: null,
                chinese_name: "",
            });
        }
    }, [showEditModal, selectEditItem])
    
    

    useEffect(() => { 
        dispatch(getCategoriesThunk())
    },[dispatch])

    const [imagePreview, setImagePreview] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            image: file,
        }));

        // Display image preview
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    }

    const removeImage = () => {
        setFormData((prevData) => ({
            ...prevData,
            image: null,
            imageAction: "deleteImage"
        }));
        setImagePreview(null);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await dispatch(editItemThunk(selectEditItem.id, formData));
            if (response && response.status === 200) {
                swal("Item updated successfully", {
                    icon: "success",
                });
                setShowEditModal(false);
            } else {
                swal("Failed to update item", {
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error updating item:", error);
            swal("Failed to update item", {
                icon: "error",
            });
        }
    }

    const handleItemDisabled = () => {

      dispatch(updateDisableItemThunk(selectEditItem.id));
    }
    


    return (
    <Modal show={showEditModal} onHide={selectedEditOption} size="lg" centered>
        <Modal.Header closeButton>
            <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="col-xl-12 col-lg-12">
          <div className="card">

          <div className="card-header">
            <h4 className="card-title">Disable Item?</h4>
          <div class="checkbox-wrapper-49">
            <div class="block">
          <input data-index="1" 
          id="cheap-49" 
          type="checkbox" 
          defaultChecked={itemDisabled} 
          onChange={handleItemDisabled}
          
          />
          <label for="cheap-49"></label>
           </div>
          </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit}>
                  
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Demo Product Name"
                        value={formData.name}
                        name="name"
                        onChange={handleInputChange}
                        required
                        defaultValue={selectEditItem?.name}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Chinese Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="中文名"
                        value={formData.chinese_name}
                        name="chinese_name"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Price</label>
                      <input
                        type="decimal"
                        className="form-control"
                        placeholder="$Price (10.99)"
                        value={formData.price}
                        name="price"
                        onChange={handleInputChange}
                        min={0}
                        step={1}
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Category</label>
                      <select
                        className="form-control"
                        value={formData.category_id}
                        name="category_id"
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Category</option>
                        {categories?.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      </div>
                  </div>
                    <div className="form-group mb-3">
                        <label>Description</label>
                        <textarea
                        className="form-control"
                        placeholder="Description"
                        value={formData.description}
                        name="description"
                        onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="form-group mb-3">
                        <label>Image</label>
                        <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={handleImageChange}
                        />
                        {imagePreview && (
                            <div>
                        <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ height: 100, marginTop: 10 }}
                        />
                        <button type="button" className="btn btn-danger" onClick={removeImage}><i className="fa fa-trash"></i></button>
                        </div>
                        )}
                    </div>
                  <button type="submit" className="btn btn-primary">
                    Edit Product
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        

        </Modal.Body>


        <Modal.Footer>
        </Modal.Footer>
    </Modal>
  );
}

export default EditProducts;
