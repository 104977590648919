import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCouponThunk, deleteCouponThunk, getAllCouponsThunk } from "../../../store/coupons";
import { Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { addFreeItemThunk, createRedeemableThunk, deleteFreeItemThunk, deleteRedeemableThunk, getFreeItemsThunk, updateRedeemableThunk } from "../../../store/freeitems";
import { getItemsThunk } from "../../../store/items";
import { FaPlus, FaMinus } from 'react-icons/fa';

function Coupons() { 
    const dispatch = useDispatch();
    const coupons = useSelector((state) => state.coupons.coupons);
    const [createModal, setCreateModal] = useState(false);
    const [inputType, setInputType] = useState('Percentage');
    const [value, setValue] = useState('');
    const [expiration, setExpiration] = useState('1 day');
    const [use_limit, setUseLimit] = useState(null);
    const [min_require, setMinRequire] = useState(0);


    const [reedemName, setRedeemName] = useState(''); 
    const [reedemModal, setRedeemModal] = useState(false);
    const [editReedemModal, setEditReedemModal] = useState(false);
    const [currentRedeemable, setCurrentRedeemable] = useState(null);

    const items = useSelector((state) => state.items.items);
    const freeitems = useSelector((state) => state.freeItems.freeitems)
    const [createItemModal, setCreateItemModal] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [needAmount, setNeedAmount] = useState(0);

    const[loading, setLoading] = useState(false);
    useEffect(() => { 
        dispatch(getAllCouponsThunk());
        dispatch(getFreeItemsThunk());
        dispatch(getItemsThunk());
    }, [dispatch]);

    const handleInputChange = (e) => {
        setValue(e.target.value);
    };

    const handleExpirationChange = (e) => {
        setExpiration(e.target.value);
    };

    const handleCreateCoupon = async () => {
        // Handle coupon creation logic here
        setCreateModal(false);

        // Reset form
        setInputType('Percentage');
        setValue('');
        setExpiration('1 day');

        await dispatch(createCouponThunk({ 
            discount: value, 
            discount_type: inputType, 
            expiration: expiration ,
            use_limit,
            min_require
        }))

        swal({
            title: "Success",
            text: "Coupon created successfully",
            icon: "success",
            button: "Ok",
        });

    };

    const handleDeleteCoupon = async (couponId) => {
        // Handle coupon deletion logic here
        await dispatch(deleteCouponThunk(couponId));

        swal({
            title: "Success",
            text: "Coupon deleted successfully",
            icon: "success",
            button: "Ok",
        });
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };
        return date.toLocaleDateString(undefined, options);
    };

    const handleCreateItem = async (id, itemId) => {

        // Handle free item creation logic here
        try{
            setLoading(true);
       await dispatch(addFreeItemThunk(id,itemId, quantity))
        }catch(err){
            swal({
                title: "Error",
                text: "Error adding item",
                icon: "error",
                button: "Ok",
            });
            setLoading(false);

        }finally{ 
            setLoading(false);
        }
    }

    const handleDeleteItem = async (freeitemId) => {
        // Handle free item deletion logic here
        try{
            setLoading(true);
        await dispatch(deleteFreeItemThunk(freeitemId));
    }catch(err){
        swal({
            title: "Error",
            text: "Error adding item",
            icon: "error",
            button: "Ok",
        });
        setLoading(false);

    }finally{ 
        setLoading(false);
    }
    }

    const handleCreateRedeemable = async () => {
        // Handle redeemable creation logic here
        setRedeemModal(false);

        // Reset form
        setRedeemName('');
        setNeedAmount(0);

        await dispatch(createRedeemableThunk(reedemName, needAmount));

        swal({
            title: "Success",
            text: "Redeemable created successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleUpdateRedeemable = async (id) => {
        // Handle redeemable update logic here

        setEditReedemModal(false);

        // Reset form
        setRedeemName('');
        setNeedAmount(0);

        await dispatch(updateRedeemableThunk(id,reedemName, needAmount));

        swal({
            title: "Success",
            text: "Redeemable updated successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDeleteRedeemable = async (id) => {
        // Handle redeemable deletion logic here
        setEditReedemModal(false);

        setRedeemName('');
        setNeedAmount(0);

        await dispatch(deleteRedeemableThunk(id));

        swal({
            title: "Success",
            text: "Redeemable deleted successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleClickEdit = (reedemable) => {
        setCurrentRedeemable(reedemable);
        setRedeemName(reedemable.name);
        setNeedAmount(reedemable.need_amount);
        setEditReedemModal(true);
    }



    return ( 
        <div className="container mt-4">
            <Button variant="primary" onClick={() => setCreateModal(true)}>
                Create Coupon
            </Button>
            <div className="row mt-4">
            {!coupons.length && <p className="text-red">No Coupons Found!</p>}
        {coupons &&
            coupons.map((coupon) => (
                <div className="col-lg-4 col-md-4 mb-3" key={coupon.id}>
                    <div className="card">
                        <div className="card-body text-center">
                            <p className="card-text">{coupon.description}</p>
                            <p className="card-text">
                                <strong>Code: {coupon.coupon_id}</strong>
                            </p>
                            <p className="card-text">
                                <strong>Discount: {coupon.discount}

                                {coupon.discount_type === 'Percentage' ? '%' : '$'}
                                </strong>
                            </p>
                            <p className="card-text">
                                <strong>Use Limit: {coupon.use_limit}</strong>
                            </p>
                            <p className="card-text">
                                <strong>Min $ Require: {coupon.min_require}</strong>
                            </p>
                            <p className="card-text">
                                <strong>Expiration: {formatDate(coupon.expiration_date)}</strong>
                            </p>
                            <Button variant="danger" onClick={() => handleDeleteCoupon(coupon.coupon_id)}>
                                Delete
                            </Button>
                  
                        </div>
                    </div>
                </div>
            ))}
    </div>

    <hr />
            <Button variant="secondary" onClick={() => setRedeemModal(true)}>
                Create Free Item
            </Button>

            <div className="row mt-4">
                {!freeitems.length && <p className="text-red">No Free Product Found!</p>}

                {freeitems &&
                    freeitems?.map((freeitem) => (
                        <div className="col-lg-4 col-md-4 mb-3" key={freeitem?.id}>
                            <div className="card">
                                <div className="card-body text-center">
                                <p className="card-text"><strong>{freeitem.name}</strong></p>

                            <Button variant="primary" onClick={() => handleClickEdit(freeitem)}>
                                Edit
                            </Button>
                            {'       '}   
                            <Button variant="primary" onClick={() => {
                                setCreateItemModal(true);
                                setCurrentRedeemable(freeitem);
                    
                            }}>
                                Add 
                            </Button>

                                </div>
                            </div>
                        </div>
                    ))}
            </div>


            {/** Create Redeemable Modal */}
            <Modal show={reedemModal} onHide={() => setRedeemModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Redeemable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={reedemName}
                                onChange={(e) => setRedeemName(e.target.value)}
                                placeholder="Enter Free Item name"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Need Amount</Form.Label>
                            <Form.Control
                                type="number"
                                value={needAmount}
                                onChange={(e) => setNeedAmount(e.target.value)}
                                placeholder="Enter need amount"
                                required
                                min={0}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setRedeemModal(false)}>Close</Button>
                    <Button variant="primary" onClick={() => handleCreateRedeemable()}>Create</Button>
                </Modal.Footer>
            </Modal>

            {/** Edit Redeemable Modal */}
            <Modal show={editReedemModal} onHide={() => setEditReedemModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Redeemable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={reedemName}
                                onChange={(e) => setRedeemName(e.target.value)}
                                placeholder="Enter Free Item name"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Need Amount</Form.Label>
                            <Form.Control
                                type="number"
                                value={needAmount}
                                onChange={(e) => setNeedAmount(e.target.value)}
                                placeholder="Enter need amount"
                                required
                                min={0}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDeleteRedeemable(currentRedeemable.id)}>Delete</Button>
                    <Button variant="primary" onClick={() => handleUpdateRedeemable(currentRedeemable.id, reedemName)}>Update</Button>
                </Modal.Footer>
            </Modal>
                                


            {/** Create Modal */}
            <Modal show={createModal} onHide={() => setCreateModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label as="legend" column sm={2}>
                                Type
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Check 
                                    type="radio"
                                    label="Percentage"
                                    name="discountType"
                                    value="percentage"
                                    checked={inputType === 'Percentage'}
                                    onChange={() => setInputType('Percentage')}
                                    inline
                                />
                                <Form.Check 
                                    type="radio"
                                    label="Amount"
                                    name="discountType"
                                    value="amount"
                                    checked={inputType === 'Amount'}
                                    onChange={() => setInputType('Amount')}
                                    inline
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{inputType === 'Percentage' ? 'Percentage' : 'Amount'}</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={value} 
                                onChange={handleInputChange} 
                                placeholder={inputType === 'Percentage' ? 'Enter percentage' : 'Enter amount'}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Use Limit</Form.Label>
                            <Form.Control
                                type="number"
                                value={use_limit}
                                onChange={(e) => setUseLimit(e.target.value)}
                                placeholder="Enter use limit"
                                required
                                min={1}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Min Spend Require</Form.Label>
                            <Form.Control
                                type="number"
                                value={min_require}
                                onChange={(e) => setMinRequire(e.target.value)}
                                placeholder="Enter min $ require"
                                required
                                min={0}
                            />
                        </Form.Group>


                        <Form.Group className="mb-3">
                            <Form.Label>Expiration</Form.Label>
                            <Form.Select value={expiration} onChange={handleExpirationChange} required>
                                <option value="1day">1 day</option>
                                <option value="1week">1 week</option>
                                <option value="1month">1 month</option>
                                <option value="unlimited">Unlimited</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCreateModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleCreateCoupon}>Create</Button>
                </Modal.Footer>
            </Modal>

            {/** Create Item Modal */}
            <Modal show={createItemModal} onHide={() => setCreateItemModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create Free Item ({currentRedeemable?.name})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Row>
                    <Col style={{ maxHeight: '600px', overflow: 'auto', borderRight: '1px solid gray', scrollbarWidth: 'thin' }}>
  <h5>Available Items</h5>
  {items.filter((item) => !currentRedeemable?.ReedemableItems?.find((redeemableItem) => redeemableItem.item_id === item.id)
  ).map((item) => (
    <div key={item.id} className="d-flex justify-content-between align-items-center">
      <span>{item.name} - {item.chinese_name}</span>
      <Button variant="link" onClick={() => handleCreateItem(currentRedeemable.id, item.id)}>
      <FaPlus />  
    </Button>
    </div>
  ))}
</Col>
            <Col  style={{maxHeight:'600px', overflow:'auto'}}>
              <h5>Selected Items</h5>
              {currentRedeemable?.ReedemableItems?.map(item => (
                <div key={item?.Item?.id} className="d-flex justify-content-between align-items-center">
                  <span>{item?.Item?.name} - {item?.Item?.chinese_name} - {item.quantity}</span>
                  <Button variant="link"onClick={() => handleDeleteItem(item.id)}>
                    <FaMinus />
                  </Button>
              </div>
              ))}
            </Col>
          </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={quantity} 
                                onChange={(e) => setQuantity(e.target.value)} 
                                placeholder="Enter quantity"
                                required
                            />
                        </Form.Group>
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {loading && <Spinner animation="border" variant="primary" />}
                    <Button variant="secondary" onClick={() => setCreateItemModal(false)}>Close</Button>
                </Modal.Footer>

                
            </Modal>

         
        </div>
    );
}

export default Coupons;
