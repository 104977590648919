import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFeeThunk, getFeesThunk, updateDeliveryFeeThunk, updateFeeThunk } from "../../../store/fees";
import { Row, Card, Col, Button, Modal } from "react-bootstrap";

function Fees() {
  const dispatch = useDispatch();
  const fees = useSelector((state) => state.fees.fees);
  const [updateModal, setUpdateModal] = useState(false);
  const [commission, setCommission] = useState(0);
  const [tax, setTax] = useState(0);
  const [delivery_fee, setDeliveryFee] = useState(0);
  const [service_fee, setServiceFee] = useState(0);

  //custom delivery fee in miles
  const [betweenZeroToOne, setBetweenZeroToOne] = useState(0);
  const [betweenOneToTwo, setBetweenOneToTwo] = useState(0);
  const [betweenTwoToThree, setBetweenTwoToThree] = useState(0);
  const [betweenThreeToFour, setBetweenThreeToFour] = useState(0)
  const [betweenFourToFive, setBetweenFourToFive] = useState(0);
  const [moreThanFive, setMoreThanFive] = useState(0)
  
  console.log(fees)


  useEffect(() => {
    dispatch(getFeesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (fees && fees.commission_fee !== undefined && fees.tax !== undefined) {
      setCommission(fees.commission_fee);
      setTax(fees.tax);
      setDeliveryFee(fees.delivery_fee);
      setServiceFee(fees.service_fee);
    } else {
      // Set commission and tax to 0 if fees don't exist or are undefined
      setCommission(0);
      setTax(0);
      setDeliveryFee(0);
      setServiceFee(0);
    }
  }, [fees]);

  const toggleUpdateModal = () => {
    setUpdateModal(!updateModal);
  };

  const handleCommissionChange = (e) => {
    setCommission(e.target.value);
  };

  const handleTaxChange = (e) => {
    setTax(e.target.value);
  };

  const handleUpdate = () => {
    if (fees.message) {
      dispatch(createFeeThunk(commission, tax, delivery_fee, service_fee));
      setUpdateModal(true);
    } else {
      dispatch(updateFeeThunk(fees.id, { commission_fee: commission, tax_percentage: tax, delivery_fee: delivery_fee, service_fee: service_fee}));
      setUpdateModal(true);
    }
  };

  const handleUpdateCustomDeliveryFee = () => { 
    dispatch(updateDeliveryFeeThunk(fees.id, {betweenOneToTwo, betweenTwoToThree, betweenThreeToFour, betweenFourToFive, betweenZeroToOne, moreThanFive}))
    setUpdateModal(true)
  }

  return (
    <div className="col-xl-12 col-lg-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Fees</h4>
        </div>
        <div className="card-body">
          <div className="basic-form">
            <form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col sm={3}>
                <p>Commission Fee</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Convenience fee"
                    value={commission}
                    onChange={handleCommissionChange}
                  />
                </Col>
                <Col sm={3} className="mt-2 mt-sm-0">
                <p>Tax Percentage</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tax Percentage"
                    value={tax}
                    onChange={handleTaxChange}
                  />
                </Col>
                <Col sm={3} className="mt-2 mt-sm-0">
                <p>Delivery Fee</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Delivery Fee"
                    value={delivery_fee}
                    onChange={(e) => setDeliveryFee(e.target.value)}
                  />
                </Col>
                <Col sm={3} className="mt-2 mt-sm-0">
                <p>Service Fee</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Service Fee"
                    value={service_fee}
                    onChange={(e) => setServiceFee(e.target.value)}
                  />
                </Col>
                <Col sm={3} className="mt-2 mt-sm-0">
                    <p>&nbsp;</p>
                  <Button onClick={handleUpdate} type="submit" className="btn btn-primary">Apply</Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>


      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Custom Delivery Fee</h4>
        </div>
        <div className="card-body">
          <form onSubmit={(e) => e.preventDefault()}>
          <div className="row">
          <div className="col-sm-4 mt-2 mt-sm-0">
          <label>
            Between 0 - 1 Miles
          </label>
          <input 
          type="text"
          className="form-control"
          value={betweenZeroToOne}
          onChange={(e) => setBetweenZeroToOne(e.target.value)}
          />
        </div>
          <div className="col-sm-4 mt-2 mt-sm-0">
          <label>
            Between 1 - 2 Miles
          </label>
          <input 
          type="text"
          className="form-control"
          value={betweenOneToTwo}
          onChange={(e) => setBetweenOneToTwo(e.target.value)}
          />
        </div>
          <div className="col-sm-4 mt-2 mt-sm-0">
          <label>
            Between 2 - 3 Miles
          </label>
          <input 
          type="text"
          className="form-control"
          value={betweenTwoToThree}
          onChange={(e) => setBetweenTwoToThree(e.target.value)}
          />
        </div>
        </div>
        <div className="row">
          <div className="col-sm-4 mt-2 mt-sm-0">
          <label>
            Between 3 - 4 Miles
          </label>
          <input 
          type="text"
          className="form-control"
          value={betweenThreeToFour}
          onChange={(e) => setBetweenThreeToFour(e.target.value)}
          />
        </div>
          <div className="col-sm-4 mt-2 mt-sm-0">
          <label>
            Between 4 - 5 Miles
          </label>
          <input 
          type="text"
          className="form-control"
          value={betweenFourToFive}
          onChange={(e) => setBetweenFourToFive(e.target.value)}
          />
        </div>
        <div className="col-sm-4 mt2 mt-sm-0">
          <label>
            Greater than 5, Charge this price per each mile
          </label>
          <input 
          className="form-control"
          type="text"
          value={moreThanFive}
          onChange={(e) => setMoreThanFive(e.target.value)}
            />
        </div>
        </div>
        <Col sm={3} className="mt-2 mt-sm-0">
                  <Button onClick={handleUpdateCustomDeliveryFee} type="submit" className="btn btn-primary">Apply</Button>
                </Col>        </form>
        </div>

        </div>

      <Modal show={updateModal} onHide={toggleUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>The fees have been updated successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleUpdateModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Fees;
