// Import fetch if not already imported
import { getToken } from "./utlits";

// const apiUrl = process.env.REACT_APP_API_URL;

// Action Types
const GET_ALL_ATTRIBUTES = "attributes/GET_ALL_ATTRIBUTES";
const CREATE_ATTRIBUTE = "attributes/CREATE_ATTRIBUTE";
const DELETE_ATTRIBUTE = "attributes/DELETE_ATTRIBUTE";
const UPDATE_ATTRIBUTE = "attributes/UPDATE_ATTRIBUTE";
const CREATE_ITEM_ATTRIBUTE = "attributes/CREATE_ITEM_ATTRIBUTE";
const DELETE_ITEM_ATTRIBUTE = "attributes/DELETE_ITEM_ATTRIBUTE";
const UPDATE_ITEM_ATTRIBUTE = "attributes/UPDATE_ITEM_ATTRIBUTE";

// Action Creators
const getAllAttributes = (attributes) => ({
  type: GET_ALL_ATTRIBUTES,
  attributes,
});

const createAttribute = (attribute) => ({
  type: CREATE_ATTRIBUTE,
  attribute,
});

const deleteAttribute = (attributeId) => ({
  type: DELETE_ATTRIBUTE,
  attributeId,
});

const updateAttribute = (attribute) => ({
  type: UPDATE_ATTRIBUTE,
  attribute,
});

const createItemAttribute = (itemAttribute) => ({
    type: CREATE_ITEM_ATTRIBUTE,
    itemAttribute,
    });

const deleteItemAttribute = (itemAttributeId) => ({
    type: DELETE_ITEM_ATTRIBUTE,
    itemAttributeId,
    });

const updateItemAttribute = (itemAttribute) => ({
    type: UPDATE_ITEM_ATTRIBUTE,
    itemAttribute,
    });


// Thunks
export const getAllAttributesThunk = () => async (dispatch) => {
  const token = getToken();
  if(!token) return null;

  const response = await fetch(`/api/attributes`, { 
    headers: { 
      'Content-Type': 'application/json',
      credentials: 'include',
      'authorization': `Bearer ${token}`
    }

  });
  const attributes = await response.json();
  dispatch(getAllAttributes(attributes));
  return attributes;
};

export const createAttributeThunk = (name) => async (dispatch) => {
  const token = getToken();
  if(!token) return null;

  const response = await fetch(`/api/attributes`, {
    method: "POST",
    body: JSON.stringify(name),
    headers: { 
      'authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

  });
  const newAttribute = await response.json();
  dispatch(createAttribute(newAttribute));
  return newAttribute;
};

export const deleteAttributeThunk = (attributeId) => async (dispatch) => {
  const token = getToken();
  if(!token) return null;

  const response = await fetch(`/api/attributes/${attributeId}`, {
    method: "DELETE",
    headers: { 
      'Content-Type': 'application/json',
      credentials: 'include',
      'authorization': `Bearer ${token}`
    }

  });
  const deletedAttribute = await response.json();
  dispatch(deleteAttribute(deletedAttribute.id));
  return deletedAttribute;
};

export const updateAttributeThunk = (name, require_number,id) => async (dispatch) => {
  const token = getToken();
  if(!token) return null;
  
  const response = await fetch(`/api/attributes/${id}`, {
    method: "PUT",
    body: JSON.stringify({ name, require_number }),
    headers: { 
      'Content-Type': 'application/json',
      credentials: 'include',
      'authorization': `Bearer ${token}`
    }

  });
  const updatedAttribute = await response.json();
  dispatch(updateAttribute(updatedAttribute));
  return updatedAttribute;
};

export const createItemAttributeThunk = (id, attributeOption) => async (dispatch) => { 
  const token = getToken();
  if(!token) return null;
  
    const response = await fetch(`/api/attributes/${id}/options`, { 
        method: "POST",
        body: JSON.stringify(attributeOption),
        headers: { 
          'Content-Type': 'application/json',
          credentials: 'include',
          'authorization': `Bearer ${token}`
        },
    });
    const newItemAttribute = await response.json();
    // dispatch(createItemAttribute(newItemAttribute));
    dispatch(getAllAttributesThunk());
    return newItemAttribute;
}

export const deleteItemAttributeThunk = (attribute_id, attributeOption_id) => async (dispatch) => {
  const token = getToken();
  if(!token) return null;
  
    const response = await fetch(`/api/attributes/${attribute_id}/options/${attributeOption_id}`, {
        method: "DELETE",
          headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
          }
        
    });
    const deletedItemAttribute = await response.json();
    dispatch(deleteItemAttribute(deletedItemAttribute.id));
    dispatch(getAllAttributesThunk());
    return deletedItemAttribute;
}

export const updateItemAttributeThunk = (attribute_id, attributeOption_id, attributeOption ) => async (dispatch) => {
  const token = getToken();
  if(!token) return null;

    const response = await fetch(`/api/attributes/${attribute_id}/options/${attributeOption_id}`, {
        method: "PUT",
        body: JSON.stringify(attributeOption),
          headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
          }
        
    });
    const updatedItemAttribute = await response.json();
    dispatch(updateItemAttribute(updatedItemAttribute));
    dispatch(getAllAttributesThunk());
    return updatedItemAttribute;
}

// Initial State
const initialState = { attributes: null };

// Reducer
const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ATTRIBUTES:
      return { ...state, attributes: action.attributes };
    case CREATE_ATTRIBUTE:
      return { ...state, attributes: [...state.attributes, action.attribute] };
    case DELETE_ATTRIBUTE:
      const newAttributes = state.attributes.filter((attribute) => attribute.id !== action.attributeId);
      return { ...state, attributes: newAttributes };
    case UPDATE_ATTRIBUTE:
      const updatedAttributes = state.attributes.map((attribute) =>
        attribute.id === action.attribute.id ? action.attribute : attribute
      );
      return { ...state, attributes: updatedAttributes };
    case CREATE_ITEM_ATTRIBUTE:
      const updatedAttributesWithItem = state.attributes.map((attribute) => {
          return {
            ...attribute,
            Item_Attribute_Options: [...attribute.Item_Attribute_Options, action.itemAttribute],
          };
      });
      return { ...state, attributes: updatedAttributesWithItem };
    case DELETE_ITEM_ATTRIBUTE:
      const updatedAttributesWithoutItem = state.attributes.map((attribute) => {
        if (attribute.id === action.itemAttributeId) {
          return {
            ...attribute,
            Item_Attribute_Options: attribute.Item_Attribute_Options.filter(
              (option) => option.id !== action.itemAttributeId
            ),
          };
        }
        return attribute;
      });
      return { ...state, attributes: updatedAttributesWithoutItem };
    case UPDATE_ITEM_ATTRIBUTE:
      const updatedAttributesWithUpdatedItem = state.attributes.map((attribute) => {
        if (attribute.id === action.itemAttribute.attribute_id) {
          return {
            ...attribute,
            Item_Attribute_Options: attribute.Item_Attribute_Options.map((option) =>
              option.id === action.itemAttribute.id ? action.itemAttribute : option
            ),
          };
        }
        return attribute;
      });
      return { ...state, attributes: updatedAttributesWithUpdatedItem };
    default:
      return state;
  }
};

export default attributesReducer;
