import { getToken } from "./utlits";

const GET_TODAY_SALES = 'GET_TODAY_SALES';
const GET_TOP_SELLING_ITEMS = 'GET_TOP_SELLING_ITEMS';
const CHANGE_ONLINE_STATUS = 'CHANGE_ONLINE_STATUS';
const GET_RESTAURANT = 'GET_RESTAURANT';
const GET_SALES = 'GET_SALES';
const UPDATE_SMS_NOTIFICATION = 'UPDATE_SMS_NOTIFICATION';


const getTodaySales = (sales) => ({
    type: GET_TODAY_SALES,
    sales
})

const getTopSellingItems = (topSellingItems) => ({
    type: GET_TOP_SELLING_ITEMS,
    topSellingItems
})

const getSales = (sales) => ({
    type: GET_SALES,
    sales
})

const updateSMSNotification = (restaurant) => ({
    type: UPDATE_SMS_NOTIFICATION,
    restaurant
})


export const changeOnlineStatus = (restaurant) => ({
    type: CHANGE_ONLINE_STATUS,
    restaurant
})

export const getRestaurant = (restaurant) => ({
    type: GET_RESTAURANT,
    restaurant
})

export const getTodaySalesThunk = (restaurant_id) => async (dispatch) => {
    const response = await fetch(`/api/restaurants/today_sales/${restaurant_id}`);

    if (response.ok) {
        const sales = await response.json();
        dispatch(getTodaySales(sales));
    }
}

export const getTopSellingItemsThunk = (restaurant_id) => async (dispatch) => {
    const response = await fetch(`/api/restaurants/top_selling_items/${restaurant_id}`);

    if (response.ok) {
        const sales = await response.json();
        dispatch(getTopSellingItems(sales));
    }
}

export const changeOnlineStatusThunk = (restaurant_id) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/restaurants/${restaurant_id}/online_status`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    });

    if (response.ok) {
        const restaurant = await response.json();
        dispatch(changeOnlineStatus(restaurant));
    }
}

export const getRestaurantThunk = (restaurant_id) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/restaurants/admin/${restaurant_id}`, { 
        headers: {
            'authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        const restaurant = await response.json();
        dispatch(getRestaurant(restaurant));

    }
}

export const getSalesThunk = (restaurant_id, startDate, endDate) => async (dispatch) => {
    let url = `/api/restaurants/sales/${restaurant_id}`;
    
    if (startDate && endDate) {
        url += `?start=${startDate}&end=${endDate}`;
    } else if (startDate) {
        url += `?start=${startDate}`;
    }

    const response = await fetch(url);

    if (response.ok) {
        const sales = await response.json();
        dispatch(getSales(sales));
    }
}

export const updateSMSNotificationThunk = (restaurant_id, sms_noti_phone) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/restaurants/${restaurant_id}/sms_noti_phone`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ sms_noti_phone })
    });

    if (response.ok) {
        const restaurant = await response.json();
        dispatch(updateSMSNotification(restaurant));
    }
}

const initialState = { today_sales: null, topSellingItems: null, restaurant: null, sales: null}

const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TODAY_SALES:
            return { ...state, today_sales: action.sales }
        case GET_TOP_SELLING_ITEMS:
            return { ...state, topSellingItems: action.topSellingItems }
        case GET_RESTAURANT:
            return { ...state, restaurant: action.restaurant }
        case CHANGE_ONLINE_STATUS:
            return { ...state, restaurant: action.restaurant }
        case GET_SALES:
            return { ...state, sales: action.sales }
        default:
            return state;
    }
}

export default restaurantReducer;
