import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useEffect, useState } from 'react';
import { getToken } from '../../../store/utlits';


function StripeAccount() { 
    const [stripeConnectInstance] = useState(() => {
        const fetchClientSecret = async () => {
          // Fetch the AccountSession client secret
          const token = getToken();
          if(!token) return;

          const response = await fetch('/api/stripe/account_session',{
            method: "POST", 
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",

            }
        });
                
          if (!response.ok) {
            // Handle errors on the client side here
            const {error} = await response.json();
            return undefined;
          } else {
            const {client_secret: clientSecret} = await response.json();
            return clientSecret;
          }
        }
    
        return loadConnectAndInitialize({
          // This is your test publishable API key.
          publishableKey: "pk_live_51N1jsSIzDtG53Sp0IYroac8Yq8aSbX7PQgISERDGaQNviBMnz4SGJ4RhjZws64QrELl0aJoWqtyYe5W3Ud6bqhmV00sR5crhK1",
          fetchClientSecret: fetchClientSecret,
        })
      });


    return ( 
       <div className='container'>
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectPayments />
        </ConnectComponentsProvider>
       </div>
    )
}


export default StripeAccount;
